import React from 'react';

import { PrimaryLayout } from '@lerna-monorepo/common/layouts/primary/primary.layout';
import { LayoutData } from './types';
import FaviconFile from './images/favicon.ico';
import { Project } from '@lerna-monorepo/common/enums/project.enum';
import { LanguageSlug } from '@lerna-monorepo/common/enums/languageSlug.enum';

const Layout: React.FC<LayoutData> = data => {
  const { children, pageContext } = data;
  const {
    languages,
    menuObject,
    settings,
    availableTranslations,
    basePaths,
    currentLanguage,
    metadata,
  } = pageContext;

  const defaultSeoData = settings.metadataSettings.find(item => {
    if (item.language.slug === pageContext.currentLanguage) {
      return item;
    }
  });
  
  const seoData = {
    title: metadata?.title ?? defaultSeoData.title,
    metaDescription: metadata?.description ?? defaultSeoData.description,
    favicon: FaviconFile,
  };
  const addMainLink = (text: string, currentLanguage: LanguageSlug) =>
    (text = `${text}: [Asket Group](${process.env.MAIN_URL}${
      currentLanguage !== LanguageSlug.Pl ? currentLanguage : ''
    })`);

  settings.alertsSettings.map(alertSettings => {
    if (
      !alertSettings.tomasserReturnToMainSectionSettings.content.includes(
        '[Asket Group]'
      )
    ) {
      alertSettings.tomasserReturnToMainSectionSettings.content = addMainLink(
        alertSettings.tomasserReturnToMainSectionSettings.content,
        currentLanguage as LanguageSlug
      );
    }
    return alertSettings;
  });

  const searchData = settings.searchSettings.filter(item => {
    if (item.language.slug === pageContext.currentLanguage) {
      return item;
    }
  });

  return (
    <PrimaryLayout
      availableTranslations={availableTranslations}
      seoData={seoData}
      languages={languages}
      menuData={menuObject}
      settings={settings}
      logoData={settings?.tomasserPageLogo}
      alertsSettings={settings.alertsSettings}
      returnToMainBannerDataKey="tomasserReturnToMainSectionSettings"
      comparisonFullLabelKey="tomasserComparisonFullLabel"
      searchSettings={searchData[0]}
      hasEuLogosInFooter={false}
      hasLogoInBanner={false}
      pathname={data.location.pathname}
      basePaths={basePaths}
      project={Project.Tomasser}
    >
      {children}
    </PrimaryLayout>
  );
};

export default Layout;
